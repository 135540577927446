
import { IonPage, IonButton, } from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import AuthService from '../services/auth.service';


export default {
  name: 'Activate',
  components: {
    IonPage,
    IonButton,
  },
  setup() {
    return {
      logIn,
      personAdd
    };
  },
  created(): void {
    let key ='';
    if (this.$route !== undefined && this.$route.query !== undefined && this.$route.query.key !== undefined) {
      console.log(this.$route.query.key);
      key = this.$route.query.key;
    }
    AuthService.activate(key)
    .then(res =>{
      this.success =true;
      this.error =false;
    },
    error =>{
      this.success= false;
      this.error = true;
    });
  },
  data(){
    return{
      success: false,
      error: false,
    }
  },
  computed: {

  },
  methods: {

  }
}

